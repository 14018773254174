<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AgentDropdown',
  props: ['value'],
  computed: {
    ...mapGetters(['agentList']),
  },
  data() {
    return {
      agentId: this.value,
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.agentId)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<template>
  <a-select v-model="agentId"
            :placeholder="$t('placeholders.selectAgent')"
            class="width-150"
            show-search
            :filter-option="filterOption"
            @change="handleChange">
    <a-select-option :value="0">
      [{{ $t('pre.all') }} {{ $t('common.agents') }}]
    </a-select-option>
    <a-select-option v-for="agent in agentList" :key="agent.id" :class="(agent.is_draft ? 'text-gray-5' : '')">
      {{ agent.name }}
    </a-select-option>
  </a-select>
</template>

<style scoped lang="scss">

</style>
