<template>
  <a-select
    class="export-csv-select flex-grow-1"
    v-model="selectedOptions"
    mode="multiple"
    :maxTagCount="0"
    :placeholder="placeholder"
    :disabled="disabled"
    :filter-option="filterOption"
    showArrow
  >
    <template #maxTagPlaceholder>
      {{ selectedLabel }}
    </template>
    <a-select-option
      key="all"
      value="All"
      :class="{ 'ant-select-dropdown-menu-item-selected': isAllSelected }"
    >
      <template v-if="!isAllSelected">Select All</template>
      <template v-else>Unselect All</template>
    </a-select-option>
    <a-select-option v-for="option in options" :key="option.id" :value="option.id">
      <slot name="option" v-bind="{...option}">
        {{ option.name }}
      </slot>
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'CustomMultiselect',
  emits: ['input'],
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    options: {
      type: Array,
      default: () => [],
      validator: (val) => {
        if (val.length === 0) return true
        return val.every(item =>
          typeof item === 'object' &&
          item !== null &&
          'id' in item &&
          'name' in item &&
          (typeof item.id === 'string' || typeof item.id === 'number') &&
          typeof item.name === 'string',
        )
      },
    },
    activeLabel: {
      type: String,
      default: 'Selected',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedOptions: {
      get() {
        return this.value
      },
      set(values) {
        if (values.includes('All')) {
          this.toggleSelectAll()
        } else {
          this.$emit('input', values)
        }
      },
    },
    isAllSelected() {
      return this.selectedOptions.length === this.options.length
    },
    selectedLabel() {
      return this.isAllSelected
        ? `${this.activeLabel} (All)`
        : `${this.activeLabel} (${this.selectedOptions.length})`
    },
  },
  methods: {
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.$emit('input', [])
      } else {
        this.$emit('input', [...this.options.map(option => option.id)])
      }
    },
    filterOption(input, option) {
      return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    },
  },
}
</script>

<style lang="scss">
.export-csv-select {
  .ant-select-selection--multiple .ant-select-selection__choice {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-top: 0;
    margin-left: 6px;
  }

  &.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    background: transparent;
  }
}
</style>
