export const downloadFileByLink = (link) => {
  const linkElement = document.createElement('a')
  linkElement.href = link
  linkElement.setAttribute('download', link.replace(/^.*[\\/]/, ''))
  document.body.appendChild(linkElement)
  linkElement.click()
  document.body.removeChild(linkElement)
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getStatusColor = (status) => {
  const statusL = status.toLowerCase()
  switch (statusL) {
    case 'empty':
      return 'gray'
    case 'created':
      return 'gray'
    case 'accepted':
      return 'yellow'
    case 'cashed':
      return 'orange'
    case 'disputed':
      return 'orange'
    case 'rejected':
      return 'yellow'
    case 'declined':
      return 'red'
    case 'canceled':
      return 'red'
    case 'expired':
      return 'red'
    case 'confirmed':
      return 'green'
    case 'activated':
      return 'green'
    case 'completed':
      return 'green'
  }
}
