<script>
export default {
  name: 'TextClipboard',
  props: {
    text: {
      type: [String, Number],
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isGrey: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'right',
    },
    prefix: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    isBasicTooltip() {
      return this.text.length > 10
    },
    basicText() {
      return this.isBasicTooltip ? this.text.substr(0, 10) + '..' : this.text
    },
  },
  methods: {
    clipboardHandle(v) {
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: this.basicText,
      })
    },
  },
}
</script>

<template>
  <span :class="[
    'd-flex align-items-center',
    { 'small': isSmall },
  ]">
    <span
      v-if="prefix"
      :class="[
        'mr-1',
        { 'text-gray-5': isGrey },
        { 'text-gray-5': isGrey }
      ]"
    >
      {{ prefix }}
    </span>
    <slot v-else name="prefix" />
    <a-tooltip
      v-if="isBasicTooltip"
      :title="text"
      placement="top"
      :class="[
      { 'text-gray-5': isGrey }
    ]"
    >
      <span>
        <slot name="text">
          {{ basicText }}
        </slot>
      </span>
    </a-tooltip>
    <span
      v-else
      :class="[
        { 'text-gray-5': isGrey }
      ]"
    >
      <slot name="text">
        {{ basicText }}
      </slot>
    </span>
    <a-tooltip :title="$t('tooltips.copyToClipboard')" :placement="placement">
      <a href="javascript:"><a-icon class="ml-1" type="copy" @click="clipboardHandle(text)"/></a>
    </a-tooltip>
  </span>
</template>
