<template>
  <div>
    <div class="payment-timeline">
      <a-timeline v-if="historyData && !loading" :reverse="true">
        <a-timeline-item v-for="data in historyData" :key="data.id" :color="getStatusColor(data.status)">
          {{ data.status }}
          <div class="small text-gray-5">
            {{ $moment.parseZone(data.created_at).format('DD.MM.YY') }}
            <span class="small">{{ $moment.parseZone(data.created_at).format('HH:mm:ss') }}</span>
          </div>
        </a-timeline-item>
      </a-timeline>
      <div v-else class="text-center"><a-spin /></div>
    </div>
  </div>
</template>

<script>
import PaymentsService from '../../services/api/payments/payments.service'
import { getStatusColor } from '../../utils'

export default {
  name: 'paymentStatusHistoryModal',
  props: ['paymentId'],
  data() {
    return {
      historyData: [],
      loading: true,
    }
  },
  mounted () {
    this.getHistory()
  },
  methods: {
    getStatusColor,
    async getHistory() {
      this.loading = true
      return PaymentsService.getClientPaymentStatusHistory(this.paymentId).then((response) => {
        this.historyData = response
      }).catch(error => {
        console.log(error)
        this.$message.error('Error while loading payment status history')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
