<template>
    <div>
        <div class="d-flex">
            <a-button icon="notification" @click="sendWebhook">Send new webhook with current status</a-button>
            <div class="ml-auto">
                <a-button icon="reload" type="link" @click="getWebhooks">Refresh</a-button>
            </div>
        </div>
        <div class="mt-2">
            <a-table
                :columns="webhookColumns"
                :data-source="webhooksData"
                :row-key="record => record.id"
                :loading="webhooksLoading"
                :scroll="{ y: 420 }"
                :bordered="false"
                size="small"
                class="ant-small-table"
            >
                <template v-slot:created_at="created_at">
                    {{ $moment.parseZone(created_at).format('DD.MM.YY') }}
                    <span class="small text-gray-5"><a-icon
                        type="clock-circle"/> {{ $moment.parseZone(created_at).format('HH:mm:ss') }}</span>
                </template>
                <template v-slot:payment="payment">
                    <a-icon type="credit-card"/>
                    {{ payment }}
                </template>
                <template v-slot:payment_status="payment_status">
                    {{ payment_status }}
                </template>
                <template v-slot:request="request, record">
                    <a href="javascript:" @click="showRequest(record)">
                        <a-icon type="eye"/>
                        Show</a>
                </template>
                <template v-slot:response="response, record">
                      <span v-if="response">
                        <span v-if="record.is_succeeded === true"><a-icon type="check" class="text-success"/></span>
                        <span v-else><a-icon type="close" class="text-danger"/></span>
                        {{ response }}
                      </span>
                    <span v-else class="text-gray-5"><a-icon type="clock-circle"/> waiting...</span>
                </template>
                <template v-slot:attempts="response, record">
                    <a href="javascript:" @click="showAttemptsModal(record)">
                        <a-icon type="eye"/>
                        Show</a>
                </template>
                <!--        <template v-slot:operation="text, record">-->
                <!--          <a-tooltip title="Repeat with this status" placement="bottom">-->
                <!--            <a-popconfirm-->
                <!--              title="Sure to create and send new webhook with this status?"-->
                <!--              @confirm="deleteAccrual(record.id)"-->
                <!--            >-->
                <!--                      <span class="font-size-14">-->
                <!--                        <a href="javascript:"><a-icon type="notification" class="text-primary" /></a>-->
                <!--                      </span>-->
                <!--            </a-popconfirm>-->
                <!--          </a-tooltip>-->
                <!--        </template>-->
            </a-table>
            <a-modal v-model="webhookRequestModal"
                     :destroyOnClose="true"
                     title="Payment webhooks"
                     :footer="null"
                     class="webhooks-modal"
                     width="720px"
                     height="480px"
            >
                <div v-if="currentWebhook">
                    <div v-if="currentWebhook.headers">
                        <h4>Headers:</h4>
                        <div>
                            <pre>{{ currentWebhook.headers }}</pre>
                        </div>
                    </div>
                    <div v-if="currentWebhook.content">
                        <h4>Content:</h4>
                        <div>
                            <pre>{{ currentWebhook.content }}</pre>
                        </div>
                    </div>
                </div>
                <div v-else>not found</div>
            </a-modal>
            <WebhooksAttemptsModal
                ref="webhook-attempts-modal"
            />
        </div>
    </div>
</template>

<script>
import WebhooksAttemptsModal from '@/views/bills/WebhooksAttemptsModal.vue'
import PaymentsService from '../../services/api/payments/payments.service'

const webhookColumns = [
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '30%',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Payment',
    dataIndex: 'payment_id',
    key: 'payment_id',
    width: '20%',
    scopedSlots: { customRender: 'payment' },
  },
  {
    title: 'Status',
    dataIndex: 'payment_status',
    key: 'payment_status',
    width: '30%',
    align: 'left',
    scopedSlots: { customRender: 'payment_status' },
  },
  {
    title: 'Request',
    dataIndex: 'content',
    key: 'content',
    width: '20%',
    align: 'left',
    scopedSlots: { customRender: 'request' },
  },
  {
    title: 'Response code',
    dataIndex: 'last_status',
    key: 'last_status',
    width: '20%',
    align: 'left',
    scopedSlots: { customRender: 'response' },
  },
  {
    title: 'Attempts',
    dataIndex: 'attempts',
    key: 'attempts',
    width: '20%',
    align: 'left',
    scopedSlots: { customRender: 'attempts' },
  },
  {
    title: '',
    dataIndex: 'operation',
    width: '10%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]
export default {
  name: 'paymentWebhooksModal',
  props: ['paymentId'],
  data() {
    return {
      webhookColumns,
      webhooksData: [],
      webhooksLoading: false,
      webhookRequestModal: false,
      currentWebhook: undefined,
    }
  },
  mounted() {
    this.getWebhooks()
  },
  components: {
    WebhooksAttemptsModal,
  },
  methods: {
    async getWebhooks() {
      this.webhooksLoading = true
      return PaymentsService.getClientPaymentWebhooks(this.paymentId).then((response) => {
        this.webhooksData = response
      }).catch(error => {
        console.log(error)
        this.$message.error('Error while loading payment webhooks')
      }).finally(() => {
        this.webhooksLoading = false
      })
    },
    async sendWebhook() {
      return PaymentsService.sendPaymentWebhook(this.paymentId).then(() => {
        this.$message.success('Webhook sent successfully', 1)
        this.getWebhooks().then(() => {
          setTimeout(() => {
            this.getWebhooks()
          }, 3000)
        })
      }).catch(() => {
        this.$message.error('Unable to send webhook')
      })
    },
    showRequest(webhook) {
      this.currentWebhook = webhook
      this.webhookRequestModal = true
    },
    showAttemptsModal(webhook) {
      this.$refs['webhook-attempts-modal'].isOpened = true
      this.$refs['webhook-attempts-modal'].id = webhook.id
      this.$refs['webhook-attempts-modal'].attempts = webhook.attempts
    },
  },
}
</script>

<style scoped>

</style>
