var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:[
  'd-flex align-items-center',
  { 'small': _vm.isSmall },
]},[(_vm.prefix)?_c('span',{class:[
      'mr-1',
      { 'text-gray-5': _vm.isGrey },
      { 'text-gray-5': _vm.isGrey }
    ]},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._t("prefix"),(_vm.isBasicTooltip)?_c('a-tooltip',{class:[
    { 'text-gray-5': _vm.isGrey }
  ],attrs:{"title":_vm.text,"placement":"top"}},[_c('span',[_vm._t("text",function(){return [_vm._v(" "+_vm._s(_vm.basicText)+" ")]})],2)]):_c('span',{class:[
      { 'text-gray-5': _vm.isGrey }
    ]},[_vm._t("text",function(){return [_vm._v(" "+_vm._s(_vm.basicText)+" ")]})],2),_c('a-tooltip',{attrs:{"title":_vm.$t('tooltips.copyToClipboard'),"placement":_vm.placement}},[_c('a',{attrs:{"href":"javascript:"}},[_c('a-icon',{staticClass:"ml-1",attrs:{"type":"copy"},on:{"click":function($event){return _vm.clipboardHandle(_vm.text)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }