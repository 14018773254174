<template>
<span v-if="!inline">
  {{ $moment.parseZone(date).format('DD.MM.YY') }}
  <span class="small d-block text-gray-5">
    <a-icon type="clock-circle"/>
    {{ $moment.parseZone(date).format('HH:mm') }}
  </span>
</span>
<span v-else class="d-flex align-items-center small text-gray-5">
  <a-icon class="mr-1" type="calendar"/>
  <span class="mr-1">{{ $moment.parseZone(date).format('DD.MM.YY') }}</span>
  <span class="text-gray-4">{{ $moment.parseZone(date).format('HH:mm') }}</span>
</span>
</template>

<script>
export default {
  name: 'TimeStamp',
  props: {
    date: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
